import React, { useState, useRef } from "react";
import VideoMP4 from "../../assets/2025-Hero-Video.mp4";

const Hero = () => {
  const videoRef = useRef(null);

  return (
    <div className="hero-section">
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="hero-video"
      >
        <source src={VideoMP4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div>
      <div className="video-content">
        <p className="video-mini-title">WELCOME TO BRW CONTRACTING INC</p>
        <h1 className="video-title">
          Your <span className="span-title">Trusted</span> Partner In <br />
          Complete Site Development
        </h1>
        <a
          className="video-btn ttm-btn ttm-bgcolor-darkgrey ttm-btn-size-md ttm-btn-shape-square margin_top40"
          href="/contact"
        >
          Contact Us
          <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  );
};

export default Hero;
